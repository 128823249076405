import * as React from "react"
import { graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "./layout2022"
import Seo from "../../../components/seo"

const JournalIndex = ({ data, location, children }) => {
  return (
    <Layout location={location}>
      <Seo title="journal" />
      <div className="text-center">
      </div>
    </Layout>
  )
}

export default JournalIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
